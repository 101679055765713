import React from 'react';
import clsx from 'clsx';
import styles from '../cookies.module.css';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import { meta } from 'metaData';
import { sectionRender, talkEmailLink, boldText } from '../sectionRender';

const purposeAndScope = [
  { type: 'subtitle', content: '1. Purpose and Scope' },
  {
    type: 'paragraphs',
    content: [
      `The purpose of this Supply Chain Compliance Policy (${boldText}the "Policy"${boldText}) is to share the basic principles and values of Brocoders with our Business Partners and to provide the necessary guidance on the standards that we expect them to comply with.`,
      'This policy also aims to provide guidance in the selection and monitoring of business partners of Brocoders.',
      'All employees and managers of Brocoders are obliged to comply with this policy, which constitutes an integral part of the ethical erincipals.',
      'Brocoders expects all its Business Partners to comply with this policy to the extent applicable to the relevant party and/or the transaction and takes the necessary steps to ensure this.',
    ],
  },
];

const definitions = [
  { type: 'subtitle', content: '2. Definitions' },
  {
    type: 'paragraphs',
    content: [
      `${boldText}"Brocoders"${boldText} refers to Brocoders Services OU, Tallinn, Estonia.`,
      `${boldText}"Business Partners"${boldText} include suppliers, distributors, authorized service companies and all kinds of representatives, subcontractors and consultants acting on behalf of the company.`,
      `${boldText}"Human Rights"${boldText} refers to the rights peculiar to all people regardless of gender, race, colour, religion, language, age, nationality, difference of opinion and wealth, and contains the right to an equal, free and dignified life.`,
      `${boldText}"ILO"${boldText} refers to the International Labour Organisation.`,
      `${boldText}"ILO Declaration on Fundamental Principles and Rights at Work"${boldText} refers to the ILO Declarations, which states that all member states are obliged to respect, develop and support the following four principles in good faith, even if they have not certified the relevant agreements:`,
    ],
  },
  {
    type: 'list',
    content: [
      'Freedom to form trade unions and effective recognition of the right to collective bargaining,',
      'Elimination of all forms of forced or compulsory labour,',
      'Prevention of child labour,',
      'Elimination of discrimination throughout the employment.',
    ],
  },
  {
    type: 'paragraphs',
    content: [
      `${boldText}"Money Laundering"${boldText} refers to the integration of revenues from illegal activities into the financial system as if they were obtained legally, in other words, the concealment of these revenues from illegal activities. `,
      `${boldText}"Sanctioned Party"${boldText}, refers to`,
      'Any person, organisation, ship or government, which is the subject of any possible sanctions ("Listed Persons") (for example, Those who are listed in OFAC, Specifically Designated Nationals and Blocked Persons ("SDN"); ',
      'The companies, 50% or more of the shares of which are directly or indirectly owned by the Listed Persons;',
    ],
  },
  {
    type: 'list',
    content: [
      'Natural persons who reside in and legal persons who are registered in the countries or regions that are subjected to comprehensive nationwide or regional sanctions as of the approval date of this Policy ("Countries Subject to Embargo"), such as Crimea, Cuba, Iran, North Korea and Syria; and',
      'Persons or companies owned or controlled by or acting as the representatives of governments of Countries Subject to Embargo or the Government of Venezuela.',
    ],
  },
  {
    type: 'paragraphs',
    content: [
      `${boldText}"UN"${boldText} , refers to an international organization called United Nations.`,
      `${boldText}"UN Global Principals Agreement"${boldText}, refers to the agreement that is based on the universally adopted UN declarations regarding social responsibility and sustainability practices that deal with the 10 basic areas of responsibility of the business world in the areas of human rights, labour standards, environment and anti-corruption.`,

      `${boldText}"UN Guiding Principles on Business Life and Human Rights"${boldText}, is a guidance text for countries and companies to examine, prevent and correct human rights violations encountered in the business life.`,
      `${boldText}"Universal Declaration of Human Rights (UDHR)"${boldText} is a landmark document in the history of human rights. This Declaration which was prepared by the representatives of different legal and cultural backgrounds from all around the world, was declared by the General Assembly of United Nations in Paris on 10 December 1948 as a common success criterion for all people and all nations, and it was the first documents emphasizing the universal protection of fundamental human rights.`,
    ],
  },
];

const generalPrinciples = [
  { type: 'subtitle', content: '3. General Principles' },
  {
    type: 'paragraphs',
    content: [
      'Brocoders selects its Business Partners based on criteria such as technical competencies, product and service quality, pricing, corporate reputation and financial soundness. Brocoders also evaluates the compliance risks associated with its Business Partners based on a risk-based approach in order to ensure compliance with the principles set out in this Policy. This approach requires certain levels of support to relevant Business Partners and regular checks as described below:',
    ],
  },
  {
    type: 'list',
    content: [
      'Performing Third Party Scans to ensure that Relevant Business Partners or real beneficiaries are not Sanctioned Parties,',
      'Audit of Business Partners (suppliers, distributors and technical service providers) for the assessment of the following matters:',
      'Compliance with applicable legislation and related contractual obligations,',
      'Compliance with the ethical principles and related policies,',
      'Having effective management systems.',
      'Providing the necessary training to ensure compliance with the expected standards.',
    ],
  },
  {
    type: 'paragraphs',
    content: [
      `${boldText}Admission Process${boldText}  `,
      'As the first line of defence, business and operations departments shall be responsible for carrying out a List of Prohibited Entities Inquiry (“DPS”) through a third-party scanning tool in order to verify whether the relevant party is subject to any Sanction Obligations or not before entering into any business relationship or working with a new Business Partner.',
      'The second stage is to assess whether the relevant parties comply with the Guiding Principles for Brocoders Business Partner  (“Principles”). Compliance with the principles shall be monitored via Self-Assessment Forms and Questions prepared by the Management Systems, Risk and Compliance Directorate, which is responsible for compliance. The results shall be evaluated by the relevant process owners by taking natural risk factors into consideration, and the evaluation results shall be forwarded to the department or officer responsible for compliance. In case due diligence results display any warning sign of any non-compliance, the department or officer responsible for compliance may recommend performing Advanced Due Diligence for interested parties, in case of need. In case the Advanced Due Diligence result is also negative (i.e. failure of the relevant party to comply with the Principles and/or failure to take the necessary measures to remedy the said violation), Brocoders shall refuse an offer of cooperation with such a party or stop continue the work.',
    ],
  },
];

const guidingPrinciples = [
  { type: 'subtitle', content: '4. Guiding Principles For The Business Partners ' },
  {
    type: 'paragraphs',
    content: [
      'The Guiding Principles for the Business Partners of Brocoders have been generated as a part of this Policy and Compliance Program of Brocoders and have been prepared in accordance with the UN Global Principles Agreement. All Business Partners of Brocoders are expected to comply with these Principles.',
    ],
  },
  { type: 'subtitleH3', content: '4.1. Legal Regulations' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners of Brocoders are expected to comply with all laws and regulations within the scope of their activities and sectors. In this context, suppliers, distributors and authorized services are expected to comply with competition laws, legislation on the prevention of money laundering and financing terrorists, regulations on the data privacy, laws on anti-bribery and anti-corruption, and all other applicable legislation.',
    ],
  },
  { type: 'subtitleH3', content: '4.2. Human Rights' },
  {
    type: 'paragraphs',
    content: [
      'Business partners are expected to carry out their business activities in accordance with the European Convention on Human Rights, 1950, EU Trafficking Directive, 2011, or UK Modern Slavery Act, 2015, or California Transparency in Supply Chains Act, 2010.',
    ],
  },
  { type: 'subtitleH4', content: '4.2.1. Employment' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners of Brocoders shall ensure that their activities are not associated with child labour, forced labour or labour abuse.',
      'Brocoders also expects its suppliers and distributors to adopt “zero tolerance” approaches to slavery and human trafficking in accordance with ILO Conventions and Recommendations, the Universal Declaration of Human Rights and the UN Global Principals Agreement.',
    ],
  },
  { type: 'subtitleH4', content: '4.2.2. Compliance with Labour Laws' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners are expected to comply with all labour laws of the countries, in which they carry out a business.',
      'The wage determination process shall be managed and proceeded according to the principles of competitiveness, relevant sectors, local labor market and in accordance with the terms of collective bargaining agreements, if there is any. All fees, including social benefits, shall be paid in accordance with the applicable laws and regulations.',
    ],
  },
  { type: 'subtitleH4', content: '4.2.3. Prevention of Harassment and Violence' },
  {
    type: 'paragraphs',
    content: [
      'Business partners are expected to provide a work environment where violence, harassment, and other unsafe and uncomfortable conditions arising from internal and external threats are not present. Any form of physical, verbal, sexual or psychological abuse, bullying, harassment or threat shall not be tolerated.',
    ],
  },
  { type: 'subtitleH4', content: '4.2.4. Discrimination' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners are expected to provide a working environment where no discrimination is accepted, employees are treated fairly and discrimination is not tolerated (such as race, sex, colour, national or social origin, ethnicity, religion, age, disability, sexual orientation, gender definitions or political views).',
    ],
  },
  { type: 'subtitleH4', content: '4.2.5. Freedom of Association and Collective Bargaining' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners shall respect the rights and freedoms of their employees to become members of a union and to enter into collective bargaining without fear of retaliation.',
    ],
  },
  { type: 'subtitleH3', content: '4.3. Ethics' },
  {
    type: 'paragraphs',
    content: [
      'Brocoders expects its Business Partners to perform their business fairly, honestly, in accordance with the legislation and ethical principles.',
    ],
  },
  {
    type: 'subtitleH4',
    content: ' 4.3.1. Anti-Bribery and Anti-Corruption and Anti Money Laundering',
  },
  {
    type: 'paragraphs',
    content: [
      'Business Partners are expected to comply with the applicable legislation concerning the fight against bribery and corruption and the prevention of money laundering while carrying out their activities on behalf of Opet Fuchs. All forms of bribes and the giving or receiving of anything of value, directly or indirectly, in order to gain unfair advantage and influence impartial decision-making processes is unacceptable. Any activity that may lead to Money Laundering, whether in bad faith or not, is illegal and unacceptable.',
      'All transactions shall be processed in legal books and records in a way that contains accurate, transparent and adequate explanations.',
    ],
  },
  { type: 'subtitleH4', content: '4.3.2. Conflict of Interest' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners shall not enter into personal relationships with Brocoders employees that may lead to or be perceived as a conflict of interest and damage the reputation of Brocoders.',
    ],
  },
  { type: 'subtitleH4', content: '4.3.3. Reporting' },
  {
    type: 'paragraphs',
    content: [
      `Business Partners are expected to establish effective communication channels to report violations and take the necessary precautions in a timely manner. Business Partners and employees shall be able to communicate their concerns through these communication channels without any fear of retribution or retaliation. In addition, it is possible for the employees of Business Partners to report their concerns directly to ${talkEmailLink}, the Ethics and Notification Hotline of Brocoders.`,
    ],
  },
  { type: 'subtitleH3', content: '4.4. Training and Development' },
  {
    type: 'paragraphs',
    content: [
      'Brocoders encourages its Business Partners to provide training programs and tools to their employees in order to enable them to gain more skills and competencies.',
    ],
  },
  { type: 'subtitleH3', content: '4.5. Management Systems' },
  {
    type: 'paragraphs',
    content: [
      'Business Partners are expected to have effective and functioning management systems to assure that they operate in accordance with laws, regulations and the Principles set out in this Policy.',
    ],
  },
];

const authorityAndResponsibilities = [
  { type: 'subtitle', content: ' 5. Authority and Responsibilities ' },
  {
    type: 'paragraphs',
    content: [
      'All employees and managers of Brocoders shall be responsible for complying with this policy and for implementing and supporting the relevant procedures and controls in accordance with the requirements of this Policy. Brocoders expects all its Business Partners to comply with this Policy to the extent applicable to the relevant party and the transaction and takes the necessary steps to ensure this.',
      'In case of any difference between this Policy and the applicable legislation, the more restrictive one shall prevail between this Policy and the legislation, to the extent that the relevant application does not contradict the legislation.',
    ],
  },
  ,
  {
    type: 'custom',
    content: () => (
      <p className={styles.paragraph}>
        If you are aware of any action that you believe is in violation of this Policy, applicable
        legislation or ethical principles, you may consult or report this matter through
        Notification and Information Hotline emailing to{' '}
        <a
          target="_blank"
          href="mailto:talk@brocoders.team"
          className={clsx(styles.link, styles.inline)}
        >
          talk@brocoders.team
        </a>
        . Alternatively, you can also submit your notifications and statements through our web-site{' '}
        <a
          href="https://brocoders.com/"
          className={clsx(styles.link, styles.inline)}
          target="_blank"
        >
          https://brocoders.com
        </a>
        .
      </p>
    ),
  },
  {
    type: 'paragraphs',
    content: [
      'Employees of Brocoders may consult with the Management Systems, Risk and Compliance. Department about their questions regarding this Policy and the implementation of this Policy. Violation of this policy by an employee may result in significant disciplinary punishments, including dismissal. In the event that any third party expected to act in accordance with this Policy acts in violation of this Policy then, the relevant agreements may be terminated.',
    ],
  },
];

const revisionHistory = [
  { type: 'subtitle', content: '6. Revision History' },
  {
    type: 'paragraphs',
    content: [
      'This Policy has entered into force pursuant to the decision of the Management Board Members taken on the date of 21/10/2022 Management Systems, Risk and Compliance Department shall be responsible for updating this Policy.',
    ],
  },
];

const pageSections = [
  purposeAndScope,
  definitions,
  generalPrinciples,
  guidingPrinciples,
  authorityAndResponsibilities,
  revisionHistory,
];

function SupplyChain() {
  return (
    <Layout isRed metaData={{ main: meta.cookies }}>
      <main className={clsx('inner-container', styles.main)}>
        <h1 className={styles.title}>Supply Chain Policy</h1>
        {pageSections.map((item) => sectionRender(item))}
      </main>
      <Footer />
    </Layout>
  );
}

export default SupplyChain;
